<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Оплата изменена</v-alert>
      <v-alert :value="errorMessage" type="error" class="my-0">
        {{errorAlert}}
      </v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Просмотр оплаты</span>
        </v-card-title>
        <v-card-text
          class="px-4"
        >
       
        <v-autocomplete
          label="Букет ID"
          :items="bouquetList"
          item-text="id"
          item-value="id"
          v-model.number="editedItem.bouquet_id"
          hide-details
          no-data-text="Не надено"
          clearable
          :search-input.sync="searchBouquets"
          @change="customFilter()"
        ></v-autocomplete>
        <v-text-field
          label="Клиент"
          v-if="editedItem.bouquet_id"
          :value="editedItem.client_name"
          readonly
        ></v-text-field>
        <v-text-field
          label="Расходы"
          v-if="editedItem.bouquet_id"
          :value="editedItem.amount"
          readonly
        ></v-text-field>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
         
          <v-spacer></v-spacer>
          <v-btn
            :color="existVendor ? 'grey' : 'info'" 
            :disabled="existVendor" 
            @click="!existVendor ? submitForm() : null" 
            :loading="btnLoad"
          >
            {{ existVendor ? 'Already Paid' : 'Платить' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    
    data() {
      return {
        editedItem: {
          bouquet_id: null,
          client_id: null,
          client_name: null,
          store_id: null,
          amount:null,
          payment_type_id: null,
        },
        bouquetList: [],
        searchBouquets:'',
        timerBouquets: null,
        client: [],
        existVendor: false,
        createdSuccess: false,
        errorMessage: false,
        errorAlert: '',
        btnLoad: false,
      };
    },
    watch: {
      searchBouquets(val) {
        const findBouquet = this.bouquetList.find((item) => item.id === val);
        if (findBouquet) return false;
  
        if (val && val.length >= 2) {
          if (this.timerBouquets) clearTimeout(this.timerBouquets);
  
          this.timerBouquets = setTimeout(() => {
            this.getBouquets(val);
          }, 500);
        } else {
          this.bouquetList = [];
        }
      },
    },
    methods: {
      customFilter() {
        if (this.editedItem.bouquet_id) {
          this.getVendorID(this.editedItem.bouquet_id)
          const bouquet = this.bouquetList.find((elem) => elem.id === this.editedItem.bouquet_id);
          if (bouquet) {
            this.editedItem.client_id = bouquet.client.id;
            this.editedItem.client_name = `${bouquet.client.name} (${bouquet.client.id})`;
            this.editedItem.amount = -bouquet.amount;
            this.editedItem.store_id = bouquet.store_id;
            this.editedItem.payment_type_id = 16; //VT card payment
          }
        }
      },
      getVendorID(bouquetID){
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-vendor/${bouquetID}`;
        axios
          .get(url, {
          })
          .then((response) => {
            const item = response.data
            this.existVendor = !! item.id
            console.log('vendor data', this.existVendor) 
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getBouquets(searchVal){
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;
        axios
          .get(url, {
            params: {
              bouquet_id: searchVal,
              page_limit: 999,
            },
          })
          .then((response) => {
            this.bouquetList = response.data.map((item) => {
              return {
                amount: item.amount,
                id: item.bouquet_id,
                client:item.client,
                store_id:item.store_id,
                payment_type_id:item.payment_type_id,
              };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      submitForm() {
          const validate = this.$refs.form.validate();
          if (validate) {
            this.btnLoad = true;
            const propsItem = Object.assign({}, this.editedItem);
            const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-vendor`;
    
            axios
              .post(url, propsItem)
              .then(() => {
                this.createdSuccess = true;

                setTimeout(() => {
                  this.createdSuccess = false;
                  this.editedItem.bouquet_id = null,
                  this.editedItem.client_id = null,
                  this.editedItem.client_name = null,
                  this.editedItem.amount = null,
                  this.btnLoad = false;
                  this.$emit('cancel');
                }, 1000);
              })
              .catch((error) => {
                this.errorMessage = true;
                if (error.response) {
                  if (error.response.status === 400 || error.response.status === 500) {
                      if (error.response.data && error.response.data.error) {
                        this.errorAlert = error.response.data.error;
                        this.btnLoad = false;
                      }
                  }
                }
              });
          }
        },
      cancel() {
        this.bouquetList = []
        this.createdSuccess = false
        this.$emit('cancel');
      },
    },
    computed: {
      
    },
    mounted() {
      
    }
  };
  </script>
  